h2 {
  margin-top: 30px;
}

form {
  margin: 40px 0px;
}

.form-button {
  display: flex;
  justify-content: center;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.required {
  color: #AA3939;
}

.btn-primary {
  margin: auto;
}

iframe {
  width: 100%;
  height: 250px;
  margin: 15px 0px;
}

@media screen and (max-width: 770px) {
  h2 {
    font-size: 1.5rem;
  }
}