h2 {
  margin-top: 30px;
}

form {
  margin: 40px 0px 15px 0px !important;
}

.form-group {
  display: flex;
  justify-content: centerconnect;
}

.btn-primary {
  margin: auto;
}

#signup a {
  text-decoration: none;
  color: #F4BA19;
}

#signup p {
  font-size: 0.8rem;
  margin: 15px 0px 15px 0px;
}

form a {
  color: #000 !important;
  font-weight: 700;
}

form a:hover {
  color: #000;
  text-decoration: underline;
}

@media screen and (max-width: 770px) {
  h2 {
      font-size: 1.5rem;
  }
}