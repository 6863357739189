@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;500;700&display=swap" rel="stylesheet);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* OVERALL */

html,
body,
#root {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Comfortaa", Arial, Helvetica, sans-serif;
  --bg-color: #e3fdff;
  --nav: #64c7e6;
  --button: #f4ba19;
  --button-hover: #c59205;
  --bkg-footer: #434343;
}

h1,
h2,
h3,
h4,
h5 {
  text-align: center;
  font-weight: bold;
  font-family: "Comfortaa", Arial, Helvetica, sans-serif;
}

p {
  font-family: "Comfortaa", Arial, Helvetica, sans-serif;
}

.Legal-Notice-Titles {
  text-align: justify !important;
  font-weight: 700;
}

.container-fluid {
  margin: 0px;
  padding: 0px;
}

.container-md {
  width: 500px !important;
  margin: auto;
}

.btn-primary {
  background-color: var(--button) !important;
  border: 1px solid rgb(244, 156, 25) !important;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  padding: 10px !important;
  margin: 20px 0px !important;
  width: 250px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
}

.btn-primary:hover {
  background-color: var(--button-hover) !important;
  border: 1px solid rgb(244, 156, 25) !important;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

/* HEADER */

.navbar {
  margin: 0px auto 20px auto;
  padding: 20px 0px !important;
}

.bg-blue {
  background-color: var(--nav);
}

.img-brand {
  width: 200px !important;
}

.fa-power-off {
  color: #fff;
}

.fa-heart {
  color: #fff;
}

.User-Name {
  color: #fff;
}

.btn-header {
  background-color: var(--button) !important;
  border: 1px solid rgb(244, 156, 25) !important;
  padding: 10px;
  margin: 0px;
  width: 250px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  color: #fff !important;
}

.btn-header:hover {
  background-color: var(--button-hover) !important;
  border: 1px solid rgb(244, 156, 25) !important;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  color: #fff;
}

a.nav-link {
  color: #fff !important;
}

.Favorites {
  display: flex;
  text-align: center;
  margin-right: 15px;
}

.Favorites a {
  color: #fff !important;
}

.NavConnect {
  display: flex;
  align-items: center;
}

.Disconnect {
  margin-left: 15px;
}

/* ACTION */

#actions {
  margin: 50px 0px;
}

#actions a {
  text-decoration: none;
  color: #f4ba19;
}

.return {
  display: none;
}

/* BUTTONS */

.ButtonAction {
  text-align: center;
  color: #fff;
  padding: 15px 25px;
  border: none;
  border-radius: 5px;
}

.Action {
  background-color: #f1b155;
}
.Action:hover {
  background-color: rgb(207, 146, 60);
}

/* CARDS */

.cards {
  margin: 20px 0px;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
}

.card {
  border: none;
  text-align: center;
  border: none !important;
  margin-bottom: 10px;
}

.card a {
  color: #000;
}

.card a:hover {
  color: #000;
  text-decoration: none;
}

.card-title {
  font-size: 1rem;
}

.card-body {
  padding: 0.8rem;
  font-size: 0.8rem;
}

.card-img-top {
  height: 350px;
  object-fit: cover;
}

/* USERBOARD */

.UserBoard-Left h2 {
  font-size: 1.2rem;
}

.UserBoard-Right h2 {
  text-align: left;
}

.Avatar {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}

.Avatar img {
  width: 100%;
  object-fit: cover;
}

/* OFFERDETAILS */

.Details-Img {
  height: 400px;
  width: 250px;
  object-fit: cover;
}

.Details-Text {
  display: flex;
  align-items: center;
}

.Details-Text p {
  margin: 0px;
  font-size: 1.1rem;
  font-weight: bold;
}

.Details-SeeAll {
  font-weight: 300;
  font-size: 0.8rem;
}

.Details-Card {
  width: 100%;
}

.Details-Card h4 {
  text-align: left;
  font-weight: bold;
}

.Details-Card h5 {
  text-align: left;
  color: #ff6e14;
}

.Details-Seller {
  display: flex;
  justify-content: space-between;
}

/* FOOTER */

.page-footer {
  background-color: var(--bkg-footer);
  color: #fff;
}

.page-footer a {
  text-decoration: none;
  color: #fff;
}

.page-footer a:hover {
  text-decoration: underline;
  color: #fff;
}

.page-footer img {
  width: 150px;
}

#img-footer-xs {
  display: none;
}

.love {
  width: 15px !important;
  padding-bottom: 5px;
}

.social {
  width: 30px !important;
  margin: 0px 15px;
}

/* SLIDER */

.Slider-Body {
  display: flex !important;
  justify-content: center;
}

.Slider-Image {
  position: relative;
  z-index: 0;
  -webkit-filter: brightness(70%);
          filter: brightness(70%);
}

.Slider-Text {
  position: absolute;
  width: 900px;
  z-index: 1;
  bottom: 40%;
  color: #fff;
  text-align: center;
}

/* SLIDER DETAILS */

.Slider-Size-Details {
  height: 200px;
}

.Slider-Text-Details {
  position: absolute;
  width: 100px;
  z-index: 1;
  bottom: 5%;
  color: #fff;
}

.Slider-Text-Details h3 {
  font-size: 1rem;
}

.Slider-Body-Details {
  display: flex !important;
  justify-content: center;
}

.Slider-Image-Details {
  position: relative;
  z-index: 0;
  -webkit-filter: brightness(70%);
          filter: brightness(70%);
  object-fit: cover;
  height: 200px;
  padding-left: 5px;
  padding-right: 5px;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}

.Slider-Image-Details:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.slick-prev:before,
.slick-next:before {
  color: #000;
}

@media screen and (max-width: 990px) {
  .Slider-Text {
    width: 600px;
  }
}

@media screen and (max-width: 770px) {
  h2 {
    font-size: 1.5rem;
  }
  h3 {
    font-size: 1.5rem;
    text-align: center;
  }
  .card {
    margin: 15px 0px;
  }
  .card-title {
    font-size: 0.7rem;
    text-align: left;
  }
  .card-text {
    font-size: 0.6rem;
  }
  .return {
    display: block;
  }
  .btn-header {
    display: none !important;
  }
  .img-brand {
    margin-left: 15px;
    width: 170px !important;
  }
  .social-media {
    display: flex;
    justify-content: center;
  }
  #img-footer-lg {
    display: none;
  }
  #img-footer-xs {
    text-align: center;
    margin: 20px auto;
  }
  .navbar-toggler {
    margin-right: 15px;
  }
  .burger-button {
    display: flex !important;
    justify-content: flex-end !important;
  }
  .navbar-dark .navbar-toggler {
    border-color: var(--nav);
  }
  .nav-link {
    display: flex !important;
    justify-content: center;
  }
  .hidden-sm {
    display: none;
  }
}

@media screen and (max-width: 540px) {
  .container-md {
    width: 300px;
    margin: auto;
  }
  .slick-arrow {
    display: none !important;
  }
  .Slider-Text {
    width: 300px;
    bottom: 10%;
    font-size: 0.8rem;
  }
  .Slider-Text h3 {
    font-size: 1.5rem;
  }
  .card-img-top {
    height: 200px !important;
  }
}

@media screen and (min-width: 770px) {
  .subscribe {
    border: 1px solid #fff;
    border-radius: 3px;
    text-decoration: none;
    color: #fff;
    margin: 0px;
  }
  .subscribe:hover {
    border: 1px solid #fff;
    background-color: #fff;
    text-decoration: none;
    color: var(--nav) !important;
    margin: 0px;
  }
  a.nav-link-sub:hover {
    color: var(--nav) !important;
  }
  #sub-link:hover {
    color: var(--nav) !important;
  }
  .hidden {
    display: none;
  }
}

h2 {
  margin-top: 30px;
}

form {
  margin: 40px 0px 15px 0px !important;
}

.form-group {
  display: flex;
  justify-content: centerconnect;
}

.btn-primary {
  margin: auto;
}

#signup a {
  text-decoration: none;
  color: #F4BA19;
}

#signup p {
  font-size: 0.8rem;
  margin: 15px 0px 15px 0px;
}

form a {
  color: #000 !important;
  font-weight: 700;
}

form a:hover {
  color: #000;
  text-decoration: underline;
}

@media screen and (max-width: 770px) {
  h2 {
      font-size: 1.5rem;
  }
}
h2 {
  margin-top: 30px;
}

form {
  margin: 40px 0px;
}

.form-button {
  display: flex;
  justify-content: center;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.required {
  color: #AA3939;
}

.btn-primary {
  margin: auto;
}

iframe {
  width: 100%;
  height: 250px;
  margin: 15px 0px;
}

@media screen and (max-width: 770px) {
  h2 {
    font-size: 1.5rem;
  }
}
